<template>
  <div class="coming-soon-banner" :style="translateX">
    <span
      v-for="(text, index) in bannerText"
      :key="index"
      class="text has-text-weight-semibold"
    >{{ text }}</span>
  </div>
</template>
<script>

export default {
  props: {
    indent: {
      type: String,
      default: ''
    },
  },
  computed: {
    bannerText() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push('COMING SOON')
      }
      return arr
    },
    translateX() {
      return `transform: translateX(${this.indent});`
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.coming-soon-banner {
  background-color: #FFFFFF;
  width: fit-content;
  min-width: 120vw;
  height: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  text-align: left;
  .text {
    padding: 0 40px;
    font-size: 22px;
    color: #3CDBC0;
    line-height: 40px;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .coming-soon-banner {
    height: 50px;
    .text {
      font-size: 24px;
      line-height: 50px;
    }
  }
}
</style>
