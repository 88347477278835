<template>
  <div class="us-holding-pen has-text-centered">
    <img src="@assets/images/common/logo_tm.svg" alt="logo" class="logo">
    <div class="tilted-banner banner-1">
      <ComingSoonBanner indent="-10px"/>
    </div>
    <div class="tilted-banner banner-2">
      <ComingSoonBanner indent="-60px"/>
    </div>
    <section class="main-message is-relative">
      <h2 class="msg-header has-text-weight-semibold pb-3">Yay! You’re In! 🥳</h2>
      <div class="text">Your spot in line is:</div>
      <div class="ranking has-text-weight-semibold py-4">{{ ranking }}</div>
      <div class="text px-4 mb-5">The first 1,000 people on the waitlist will get a lifetime membership for free!</div>
      <div class="text pb-3">Move up your position by taking the actions below:</div>
      <section class="referral is-flex is-justify-content-space-between is-align-items-center px-6">
        <span class="icon">🤝</span>
        <span class="action is-clickable" @click="toReferralPage">Refer a friend</span>
        <span class="bonus">+10</span>
      </section>
      <div class="desktop-only">
        <section class="app-download is-flex is-justify-content-space-between is-align-items-center px-6">
          <img src="@assets/icons/apple-icon-white-background.svg" alt="Apple" class="icon">
          <span>Download our App</span>
          <span class="bonus">+5</span>
        </section>
        <img src="https://addy-public.s3.us-west-2.amazonaws.com/addy-ios-app-American-link.svg" alt="app download link" class="app-link">
      </div>
      <div class="mobile-only">
        <section class="app-download is-flex is-justify-content-space-between is-align-items-center px-6">
          <img src="@assets/icons/apple-icon-white-background.svg" alt="Apple" class="icon">
          <a class="action" href="https://apps.apple.com/us/app/addy/id1595926089">Download our App</a>
          <span class="bonus">+5</span>
        </section>
      </div>
      <div class="text future-notification">You’ll receive an SMS or email notification as soon as we’re live!</div>
      <img src="@assets/icons/money-mouth.svg" alt="money mouth" class="left-top floating-icon">
      <img src="@assets/icons/graph.svg" alt="graph" class="left-bottom floating-icon">
      <img src="@assets/icons/money-with-wings.svg" alt="money with wings" class="right-top floating-icon">
      <img src="@assets/icons/open-mouth.svg" alt="open mouth" class="right-bottom floating-icon">
    </section>
    <div class="tilted-banner banner-3">
      <ComingSoonBanner indent="-60px"/>
    </div>
    <div class="tilted-banner banner-4">
      <ComingSoonBanner indent="-130px"/>
    </div>
    <section class="footer-wrap is-relative">
      <div class="purple-block"></div>
      <DesktopFooter :showDiscord="false"></DesktopFooter>
    </section>
  </div>
</template>
<script>
import ComingSoonBanner from './ComingSoonBanner.vue'
import DesktopFooter from '@views/index/DesktopFooter.vue'
import { fetchWaitListRanking } from '@api/profile'

export default {
  components: {
    ComingSoonBanner,
    DesktopFooter,
  },
  data() {
    return {
      ranking: 0,
    }
  },
  created() {
    this.getRankingOnWaitList()
  },
  methods: {
    toReferralPage() {
      this.$router.push('/referralDashboard')
    },
    getRankingOnWaitList() {
      fetchWaitListRanking().then((res) => {
        if (!res.success) return
        const { positionInQueue } = res.data
        this.ranking = positionInQueue
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.us-holding-pen {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background-color: #F0EEF8;
  .logo {
    width: 66px;
    margin-top: 50px;
  }
  .tilted-banner {
    position: absolute;
    left: 0;
    width: 110vw;
  }
  .banner-1 {
    top: 110px;
    transform: rotate(-4.31deg);
  }
  .banner-2 {
    display: block;
    top: 180px;
    transform: rotate(3.58deg);
  }
  .main-message {
    width: 320px;
    margin: 0 auto;
    margin-top: 190px;
    padding: 42px 20px 36px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(13, 3, 39, 0.1);
    border-radius: 12px;
    .msg-header {
      font-size: 28px;
    }
    .msg-header-2 {
      font-size: 18px;
    }
    .text {
      font-size: 16px;
      line-height: 18px;
    }
    .ranking {
      font-size: 32px;
    }
    .referral,
    .app-download {
      font-size: 16px;
      margin-top: 20px;
      .icon {
        font-size: 30px;
      }
      .action {
        color: #3366CC;
        text-decoration: underline;
      }
      .bonus {
        color: #06D928;
      }
    }
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: block;
      .download-app {
        border-radius: 12px;
      }
    }
    .future-notification {
      margin-top: 80px;
    }
    .floating-icon {
      display: none;
    }
  }
  .banner-3 {
    top: 970px;
    transform: rotate(-2.58deg);
  }
  .banner-4 {
    top: 880px;
    transform: rotate(4.31deg);
  }
  .footer-wrap {
    display: none;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .us-holding-pen {
    position: fixed;
    left: 0;
    top: 0;
    .banner-2 {
      display: none;
    }
    .banner-3 {
      top: 950px;
      z-index: 10;
    }
    .banner-4 {
      top: 860px;
    }
    .main-message {
      margin-top: 140px;
      width: 384px;
      .desktop-only {
        display: block;
        .app-link {
          width: 120px;
          margin-top: 24px;
        }
      }
      .mobile-only {
        display: none;
      }
      .future-notification {
        margin-top: 20px;
      }
      .floating-icon {
        display: block;
        position: absolute;
        &.left-top {
          width: 58px;
          left: -220px;
          top: 100px;
        }
        &.left-bottom {
          width: 44px;
          left: -400px;
          bottom: 108px;
        }
        &.right-top {
          width: 50px;
          right: -220px;
          top: 108px;
        }
        &.right-bottom {
          width: 58px;
          right: -400px;
          bottom: 108px;
        }
      }
    }
    .footer-wrap {
      display: block;
      background-color: #fdfdfd;
      margin-top: max(calc(100vh - 904px), 245px);
      z-index: 1;
      .purple-block {
        position: absolute;
        width: 100%;
        height: 18px;
        top: 0;
        left: 0;
        background-color: #F0EEF8;
      }
    }
  }
}
</style>
